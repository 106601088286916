$main: #ffffff;
$white: #ffffff;
$black: #253081;
$background: rgba($black, 100%);
$font-base: Oxygen, Ubuntu, 'Open Sans', 'Helvetica Neue', sans-serif;
$font-title: 'MazalTov', serif;

// @import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&family=Merriweather:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@font-face {
  font-family: 'MazalTov';
  src: local('MazalTov'), url(./fonts/mazaltov.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@import '~purecss/build/grids-responsive-min.css';

* {
  box-sizing: border-box;
  font-family: $font-base;
}

body {
  padding: 0;
  margin: 0;
  background-color: $main;
  color: $black;
  &.no-scroll {
    overflow-y: hidden;
  }
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  58% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

//Header

.container {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  position: relative;
  width: auto;
  height: 100%;
  &.fluid {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .row {
    display: flex;
    align-items: center;
    &.space-between {
      justify-content: space-between;
    }
    &.center {
      justify-content: center;
    }
  }
}

.link.button {
  transition: border-color 0.5s, color 0.5s;
  cursor: pointer;
  border-radius: 23px;
  padding: 12px 32px;
  border: 2px solid $black;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  color: $black;
  background-color: rgba($white, 40%);
  &:hover {
    color: $white;
    background: $black;
    a {
      color: $white;
    }
  }
  a {
    text-decoration: none;
    color: $black;
    &:hover {
      color: $white;
    }
  }
}
.link.button.left {
  border-radius: 23px 0 0 23px;
  border-right: 1px solid $black;
}
.link.button.right {
  border-radius: 0 23px 23px 0;
  border-left: 1px solid $black;
}

header {
  font-size: 16px;
  position: fixed;
  z-index: 99;
  width: 100%;
  font-weight: '700';
  //background-color: $white;
  .container {
    .row {
      height: 128px;
      .logo {
        a {
          color: $black;
          text-decoration: none;
        }
      }
      @media (max-width: 480px) {
        height: 85px;
      }
    }
  }
}
.st0 {
  fill: rgba($white, 40%);
}
.st1 {
  fill: #253080;
}
#mazal_tov_logo {
  width: 180px;
  transition: fill 2s;
  @media (max-width: 768px) {
    max-width: 25vw;
  }
}

main {
  h1.details {
    font-weight: 700;

    text-align: left;
    font-size: 4vw;
    max-width: 75vw;
    @media (max-width: 768px) {
      max-width: 90vw;
      font-size: 6vw;
    }
  }
  .scroller-img-wrapper {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    background-color: #fbead2;
    display: inline-block;
    font-size: 0;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      max-width: 100vw;
    }
    img {
      height: 100%;
      max-height: calc(40vh - 35px);
      -webkit-user-drag: none;
      @media (max-width: 768px) {
        max-width: 100vw;
        //height: 110%;
      }
    }
  }
  .container {
    position: relative;
    .row {
      height: 100vh;
      align-items: center;
      .image-container {
        position: relative;
        .thumbnail {
          overflow: hidden;
          position: relative;
          .frame {
            img {
              width: 100%;
              @media (min-width: 768px) {
                max-width: 350px;
              }
            }
          }
        }
        .information {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          text-transform: uppercase;
          .location {
            span:nth-child(2) {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .top-row {
      height: 50vh;
      width: 100%;
      align-items: flex-end;
      .top {
        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    .bottom-row {
      height: 50vh;
      position: relative;
      .bottom {
        height: 100%;
        width: 100%;
      }
    }
  }
}

//single
.single {
  margin-bottom: 15vh;
  .scroller-img-wrapper {
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    background-color: #fbead2;
    display: inline-block;
    font-size: 0;

    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      max-width: 130vw;
    }
    img {
      height: auto;
      -webkit-user-drag: none;
      @media (max-width: 768px) {
        max-width: 130vw;
      }
    }
  }
  .container {
    .top-row {
      height: 50vh;
      width: 100%;
      align-items: center;
      overflow: hidden;

      .bottom {
        height: 100%;
        width: 100%;
        background-color: $background;
        .thumbnail-single {
          width: 100%;
          height: 50vh;
          display: flex;
          align-items: center;
          margin: 0 auto;
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          .frame-single {
            //width: 100%;
            img {
              //position: absolute;
              width: 130vw;
              height: auto;
            }
          }
        }
        .thumbnail {
          width: 350px;
          margin: 0 auto;
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          .frame-single {
            img {
              position: absolute;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .bottom-row {
    //min-height: 50vh;
    position: relative;
    align-items: flex-start;
    .top {
      padding: 20px 0;
      margin: auto;
      .details {
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .page-buttons {
        display: flex;
        align-items: left;
        @media (max-width: 720px) {
          flex-wrap: wrap;
        }
        a {
          margin-right: 15px;
        }
      }
      .model {
        overflow: hidden;
        text-align: center;
        margin: 3rem 0;
        @media (max-width: 720px) {
          margin: 1rem 0;
        }
        .first {
          margin-right: 1rem;
        }
        span {
          display: inline-block;
          position: relative;
          font-weight: 400;
          font-size: 8vw;
          font-family: $font-title;
          @media (max-width: 720px) {
            font-size: 8vw;
          }
        }
      }
    }
  }
}

.detailed-information {
  width: 90vw;
  margin: auto;
  .container {
    .row {
      justify-content: space-between;
      align-items: flex-start;
      h2 {
        font-size: 28px;
        width: 20vw;
      }
      p {
        font-size: 16;
        line-height: 28px;
        font-weight: 400;
        width: calc(80vw - 20px);
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-title;
  font-weight: bold;
  text-transform: uppercase;
}

/* scroller */

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  //background: white;
  border-radius: 30px;
  border: 2px solid $white;
  color: $white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroller {
  text-align: center;
}

.scroller-wrapper {
  width: 100vw;
  height: 100vh;
  background: $background;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .scroller-text {
    margin-top: 10px;
    color: white;
    text-align: center;
    //height: 35px;
    font-size: 1.75vw;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
}
#mazal_tov_logo {
  fill: $black;
}
.dark {
  #mazal_tov_logo {
    fill: $white;
  }
  .link.button {
    color: $white;
    border-color: $white;
    background-color: transparent;
    &:hover {
      color: $black;
      background: $white;
    }
  }
}
.light {
  #mazal_tov_logo {
    fill: $black;
  }
}

.imageZoom {
  max-width: 90vw;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  margin-top: 100px;
  @media (max-height: 680px) {
    margin-top: 75px;
  }
}

.zoom .container {
  background-color: $background;
  text-align: center;
  padding: 5vw 0;
  .details {
    margin: auto;
    padding: 5vw 0;
    max-width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-height: 680px) {
      padding: 10px 0;
    }
  }
}

.icn-find:after {
  transform: rotate(45deg);
  display: block;
  content: '\26B2';
  font-size: 120%;
}

.nowrap {
  white-space: nowrap;
}

.shrinkable {
  @media (max-width: 768px) {
    transform: scale(0.7);
  }
  @media (max-width: 480px) {
    transform: scale(0.7);
  }
}

.fullheight {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
}

.album {
  max-height: 49vh;
  max-width: 85vw;
  @media (max-width: 480px) {
    max-height: calc(49vh - 78px);
  }
}

.menu {
  background-color: $black;
  color: $white;
  display: none;
  z-index: 1000;
  padding: 10vh 10vw;
  text-align: center;
  font-size: 120%;
  ul {
    list-style-type: none;
    padding: 0;

    li {
      padding-bottom: 10px;
    }
    a {
      color: $white;
      text-decoration: none;
    }
    span {
      display: inline-block;
      border-top: 1px solid $white;
      margin-top: 5px;
      padding-top: 10px;
      cursor: pointer;
    }
  }
}

.showMenu {
  display: block;
}

.indice {
  padding-top: 150px;
  text-align: left;
  ul {
    column-count: 3;
    @media (max-width: 768px) {
      column-count: 2;
    }
    @media (max-width: 480px) {
      column-count: 1;
    }
  }
  a {
    color: $black;
    text-decoration: none;
  }
}

.curatela {
  .nobreak:first-child h5 {
    margin-top: 0;
  }
  column-count: 3;
  @media (max-width: 768px) {
    column-count: 2;
  }
  @media (max-width: 480px) {
    column-count: 1;
  }
}

.loghi {
  column-count: 2;
  @media (max-width: 600px) {
    column-count: 1;
  }
}

.nobreak {
  break-inside: avoid;
}

.curatelaWrapper {
  padding-right: 10vw;
  padding-bottom: 15vh;
}

.darkbackground,
.colophon {
  background-color: $black;
  color: $white;
  padding-top: 150px;
  text-align: left;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  .paragrafo,
  .logo {
    padding-left: 10vw;
    text-align: left;
    margin-bottom: 0;
    font-weight: 100;
  }
  h2 {
    font-size: 300%;
    margin-bottom: 5px;
    font-family: $font-title;
    @media (max-width: 480px) {
      font-size: 200%;
      padding-right: 1em;
    }
  }
  h4 {
    margin-top: 0px;
  }

  h5 {
    text-transform: uppercase;
    margin-bottom: 0;
    border-bottom: 1px solid $white;
    margin-right: 20px;
    margin-left: 10vw;
    padding-left: 0;
    font-size: 100%;
  }
  ul {
    margin-block-start: 0;
    margin-block-end: 1em;
    list-style: none;
  }
  li {
    font-weight: lighter;
  }
  .loghi {
    margin-top: 15vh;
    margin-bottom: 15vh;

    .logo {
      max-height: 15vh;
      margin-top: 10px;
      @media (max-width: 600px) {
        margin-bottom: 5vh;
      }
    }
    .imgText {
      display: flex;
      align-items: center;

      span {
        padding-left: 1em;
        padding-right: 1em;
        display: inline-block;
      }
    }
  }
}

.colophon {
  min-height: 100vh;
}

.patrocini-wrapper {
  background-color: $white;
  color: $black;
  padding-bottom: 15vh;
  padding-top: 3em;
  h3,
  h4 {
    margin: 1em 0;
    font-weight: bold;
  }
  .patrocini {
    max-height: 150px;
    max-width: 90vw;
    padding-left: 10vw;
  }
  img {
    margin-bottom: 2em;
  }
}

.darkbackground ul.stanze {
  display: flex;
  flex-wrap: wrap;
  max-width: 80vw;
  gap: 12px;
  margin-bottom: 60px;
  margin-top: 30px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: left;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
  gap: 5px;
  flex-wrap: wrap;
  a {
    margin-right: 5px;
  }
}

.with-buttons {
  line-height: 150%;
}

.right {
  float: right;
}

.hidden {
  display: none;
}

#sezione {
  padding: 0 10vw;
  @media (max-width: 600px) {
    padding: 0 5vw;
  }
}

.container--page {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 5vw;

  .row {
    display: flex;
    &.space-between {
      justify-content: space-between;
    }
    &.center {
      justify-content: center;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  p {
    color: #666;
    font-weight: normal;
  }
}

.pure-g > div {
  box-sizing: border-box;
}
.l-box {
  padding: 1em;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}

.w-box {
  padding: 1em;
  height: 100%;
}

.inverted,
.fascia {
  color: $white;
  background-color: $background;
  .link--stanza {
    border-radius: 30px;
    border: 2px solid $white;
    color: $white;
    background-color: transparent;
    width: auto;
    min-width: 10rem;
    height: 40px;
    text-align: center;
    &.small {
      min-width: 40px;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  p {
    font-weight: normal;
    color: $white;
  }

  .quote {
    color: $white;
  }
}

.fascia {
  padding: 0 5vw;
}

.fullwidth {
  width: 100vw;
}

.p-15 {
  padding: 15px;
}

.p-30 {
  padding: 30px;
}

.p-60 {
  padding: 60px;
}

.fregio {
  max-width: 960px;
  margin: 0 auto 2vh auto;
  display: block;
}

.spacer-30 {
  height: 30px;
}
.spacer-60 {
  height: 60px;
}
.spacer-90 {
  height: 90px;
}

.cover-video {
  object-fit: cover;
  width: 100vw;
  height: 50vh;
}

img.responsive {
  width: 100%;
  padding: 10px;
}

.didascalia--titolo {
  font-size: 130%;
}

.didascalia--descrizione {
  font-weight: bold;
}

.stellato {
  background-image: url(./images/background_stelle.png);
  background-repeat: repeat;
  background-size: 20%;
}

.foto--matrimoni {
  display: block;
  max-width: 70vw;
  box-sizing: border-box;
  padding: 3vh 4vw;
  margin-bottom: 3em;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    padding: 3vh 2vw;
    max-width: 26vw;
  }
}

.polaroid {
  text-decoration: none;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  padding: 3vh 4vw;
  text-align: center;
  @media (min-width: 768px) {
    padding: 3vh 2vw;
  }
}

.polaroid--btn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  background-color: white;
  border: none;
  width: 100%;
  padding: 20px;
  height: calc(83.33vw + 40px);
  @media (min-width: 768px) {
    height: calc(30.56vw + 40px);
  }
}

.polaroid--img {
  cursor: pointer;
  height: calc(70vw - 20px);
  @media (min-width: 768px) {
    height: calc(24vw - 20px);
  }
}

.polaroid--text {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-family: $font-title;
  font-size: 2vh;
  color: $black;
  padding-top: 1.5vh;
  line-height: 100%;
  @media (min-width: 480px) {
    font-size: 4vh;
  }
  @media (min-width: 768px) {
    padding-top: 2vh;
    font-size: 2.1vh;
  }
  @media (min-width: 950px) {
    font-size: 2.4vh;
  }
  @media (min-width: 1200px) {
    padding-top: 2.5vh;
    font-size: 2.7vh;
  }
}

.bottone--sx,
.bottone--cx,
.bottone--dx {
  display: inline-block;
  text-decoration: none;

  border: 2px solid $white;
  color: $white;
  background-color: transparent;
  text-align: center;

  font-size: 18px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  line-height: 28px;
  border-radius: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    line-height: 38px;
    border-radius: 30px;
  }
}

.bottone--sx {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.bottone--cx {
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}
.bottone--dx {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.navigazione--sale {
  clear: left;
  float: right;

  @media (min-width: 768px) {
    clear: none;
  }
}

.logo--footer-h,
.logo--footer-w {
  padding-right: 20px;
  padding-bottom: 10px;
  display: block;
}

.logo--footer-h {
  max-height: 200px;
}

.logo--footer-w {
  width: 300px;
  max-width: 100%;
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mostra {
  padding-top: 120px;
}
